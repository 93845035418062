import Routes, { Route } from '../Routes';

const PageTitles: Record<Route, string> = {
    [Routes.HOME]: 'Dashboard',
    [Routes.LOGIN]: 'Login',
    [Routes.FORGOT_PASSWORD]: 'Forgot password',

    // Onboarding
    [Routes.LANDING]: 'Welcome',
    [Routes.REGISTER]: 'Create account',
    [Routes.YOUR_ACTIVITIES]: 'Your activities',

    // Quiz
    [Routes.ONBOARDING_QUIZ]: 'Onboarding Quiz',
    [Routes.ONBOARDING_QUIZ_INJURY]: 'Onboarding Quiz - Existing Injury',
    [Routes.ONBOARDING_QUIZ_WORK]: 'Onboarding Quiz - Work Accidents',
    [Routes.ONBOARDING_QUIZ_MOTORCYCLING]: 'Onboarding Quiz - Motorcycling',
    [Routes.ONBOARDING_QUIZ_RESULT_EXCLUSIONS]: 'Onboarding Quiz - Exclusions',
    [Routes.ONBOARDING_QUIZ_RESULT_SIGNUP]: 'Onboarding Quiz - Sign Up',

    // Checkout
    [Routes.RESIDENCY_STATE]: 'State of residence',
    [Routes.RESIDENCY_STATUS]: 'Residency status',
    [Routes.DATE_OF_BIRTH]: 'Date of birth',
    [Routes.GENDER]: 'Gender',
    [Routes.NAME]: 'Name',
    [Routes.SCHEDULE_ALWAYS_ON_SUCCESS]: 'Checkout success',
    [Routes.SCHEDULE_BOOST_SUCCESS]: 'Checkout success',
    [Routes.CHECKOUT]: 'Checkout',
    [Routes.BEFORE_YOU_GO]: 'Before you go',
    [Routes.SCHEDULE_ACTIVE_COVER_BAYMAX]: 'Schedule active cover',
    [Routes.SCHEDULE_KIDS_COVER_BAYMAX]: 'Schedule kids cover',
    [Routes.SELECT_COVER_BAYMAX]: 'Select cover',

    [Routes.ROAMING_DESTINATIONS_BAYMAX]: 'Select Destinations',
    [Routes.ROAMING_START_BAYMAX]: 'Select starting destination',
    [Routes.ROAMING_REGION_BAYMAX]: 'Select starting region',
    [Routes.ROAMING_DATES_BAYMAX]: 'Schedule Roaming cover',

    // Profile
    [Routes.PROFILE]: 'Profile',
    [Routes.PROFILE_ACCOUNT]: 'Account details',
    [Routes.PROFILE_PREFERENCES]: 'Preferences',
    [Routes.PROFILE_CERTIFICATE_OF_COVER]: 'Certificate of cover',
    [Routes.PROFILE_MANAGE_COVER]: 'Manage Cover',

    // Claims
    [Routes.CLAIMS]: 'Claims',
    [Routes.CLAIM_PERSONS_INSURED]: 'Claimant',
    [Routes.CLAIM_CHECKLIST]: 'Checklist',
    [Routes.CLAIM_TYPE]: 'Type',
    [Routes.CLAIM_REQUIREMENTS]: 'Requirements',
    [Routes.CLAIM_AUTOGRAPH]: 'Autograph',
    [Routes.CLAIM_ACCIDENT_LOCATION]: 'Accident location',
    [Routes.SELECT_INJURY]: 'Select injury',
    [Routes.ACCIDENT_DESCRIPTION]: 'Accident description',
    [Routes.THIRD_PARTY_CONSENT]: 'Third party consent',
    [Routes.ACCIDENT_DATE]: 'Accident date',
    [Routes.FIRST_TREATMENT_DATE]: 'First treatment date',
    [Routes.SURGERY_REQUIRED]: 'Surgery required',
    [Routes.MEDICAL_REPORT]: 'Medical report',
    [Routes.OVERSEAS_RECEIPTS]: 'Overseas Medical Receipts',
    [Routes.MEDICAL_CONTACT_CONSENT]: 'Health professional contact consent',
    [Routes.MEDICAL_CONTACT_INFORMATION]: 'Health professional contact consent authorities',
    [Routes.EMERGENCY_SERVICES_REQUIRED]: 'Emergency services required',
    [Routes.WITNESS_QUESTIONNAIRE]: 'Any witness',
    [Routes.WITNESS_INFO]: 'Witness info',
    [Routes.PHOTO_OF_ACCIDENT_QUESTIONNAIRE]: 'Any photo of accident',
    [Routes.PHOTO_OF_ACCIDENT]: 'Upload photo of accident',
    [Routes.PROOF_OF_ACTIVITY_QUESTIONNAIRE]: 'Any proof of activity',
    [Routes.PROOF_OF_ACTIVITY]: 'Upload proof of activity',
    [Routes.BANK_DETAILS]: 'Bank details',
    [Routes.REVIEW_CLAIM]: 'Review claim',
    [Routes.CLAIM_SUBMITTED]: 'Claim submitted',
    [Routes.CLAIM_DETAILS]: 'Claim details',

    // Intermediate states, no need to title
    [Routes.LOGIN_CALLBACK]: '',
    [Routes.LOADING]: '',

    [Routes.CART]: 'Cart',
    [Routes.CART_BAYMAX]: 'Cart',
    [Routes.CART_SUCCESS]: 'Checkout Success',
    [Routes.CART_BAYMAX_SUCCESS]: 'Cart Success',
    [Routes.NOT_FOUND]: 'Page not found',
};

export default PageTitles;
